import React from "react";
import "../../styles/assets/vendor/offcanvas-nav/hc-offcanvas-nav.css"
import "../../styles/assets/css/particle-theme.css"
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Mobile() {
    const revoLight = `https://${window.location.hostname}/images/revo-light.svg`;
    const [navState, setNavState] = useState([false, false, false]);
    const [arrStates, setArrStates] = useState(false);

    let navElements = ["Discover", "News", "Blockchain", "App", "Projects", "Learn", "Community"];
    let subMenu = [
    [
        { name: "Technology", url: "technology" }, 
        { name: "Ecosystem", url: "ecosystem" }, 
        { name: "Whitepaper", url: "https://heyzine.com/flip-book/bf81330a53.html" }, 
        { name: "Team", url: "members" }, 
        { name: "Partners", url: "partners"} 
    ],
    [],
    [
        { name: "Explorer", url: "" }, 
        { name: "Faucets", url: "" },
        { name: "Network Status", url: "network-stats" }, 
        { name: "Node map", url: "map" }, 
        { name: "API", url: "public-api" }
    ],
    [
        { name: "IDE", url: "https://remix.revo.network" }, 
        { name: "DeFI", url: "defi" }, 
        { name: "Domains", url: "domains" }, 
        { name: "Notarize Document", url: "notarize-document" }, 
        { name: "Create NFT", url: "create-nft" }
    ],
    [
        { name: "Docs", url: "project-docs" }, 
        { name: "Develop", url: "develop" }, 
        { name: "Build", url: "build" }, 
        { name: "Deploy", url: "deploy" }],
    [
        { name: "Wiki", url: "wiki" }, 
        { name: "Tutorials", url: "tutorials" }, 
        { name: "Training", url: "training" }
    ],
    [
        { name: "Forum Hub", url: "https://forum.revo.network/" }, 
        { name: "Events", url: "https://forum.revo.network/forumdisplay.php?fid=6" }, 
        { name: "Support", url: "https://forum.revo.network/forumdisplay.php?fid=49" }
    ]
    ]


    let subSubMenu = [
    [
        { name: "Mainnet", url: "https://mainnet.revo.network/" }, 
        { name: "Testnet", url: "https://testnet.revo.network/" }, 
        { name: "Alphanet", url: "https://alpha.revo.network/" }
    ],
    [
        { name: "Index", url: "public-api"},
        { name: "Block", url: "docs-api/block" }, 
        { name: "Blockchain", url: "docs-api/blockchain" }, 
        { name: "Transaction", url: "docs-api/transaction" }, 
        { name: "Address", url: "docs-api/address" }, 
        { name: "Contract", url: "docs-api/contract" }, 
        { name: "ERC20", url: "docs-api/erc20" }, 
        { name: "ERC721", url: "docs-api/erc721" }, 
        { name: "Pagination", url: "docs-api/pagination-parameters" }, 
        { name: "Block/Timestamp Filters", url: "docs-api/block-timestamp-filters" }
    ],
    [
        { name: "Index", url: "technology"},
        { name: "UTXO and Account Model", url: "technology/utxo-and-account-balance-models" }, 
        { name: "Account Abstraction Layer (AAL)", url: "technology/account-abstraction-layer" }, 
        { name: "Ethereum EVM", url: "technology/evm" }, 
        { name: "x86 Virtual Machine", url: "technology/x86-virtual-machine" }, 
        { name: "Lightning Network", url: "technology/lightning-network" }, 
        { name: "Proof of Stake", url: "technology/proof-of-stake" }, 
        { name: "Sidechains", url: "technology/sidechains" }, 
        { name: "Decentralized Domain System", url: "technology/decentralized-domain-system" }, 
        { name: "Decentralized Storage", url: "technology/decentralized-storage" }],
    [
        { name: "Testnet", url: "testnet-faucets"}
    ]];

    function handleNav(submenu) {
        if (submenu && submenu !== "Back") {
            setNavState([true, true, submenu]);
            setSubSubMenuState([false, false])
        } else if (submenu == "Back") {
            setNavState([true, true, false]);
        } else {
            if (navState[0]) {
                setNavState([false, true, false]);
            } else {
                setNavState([true, true, false]);
            }
        }

        if (arrStates) {
            setArrStates(false);
        } else {
            setArrStates(true);
        }
    }

    const [subSubMenuState, setSubSubMenuState] = useState([false, false]);

    function handleSubSubMenu(subsubmenu) {
        let position;
        if(subsubmenu == "Explorer"){
            position = 0;
        }else if (subsubmenu == "API"){
            position = 1
        }else if (subsubmenu == "Technology"){
            position = 2
        }else if (subsubmenu == "Faucets"){
            position = 3
        }
        if (subsubmenu && subsubmenu !== "Back") {            
            setSubSubMenuState([subsubmenu, position]);
        } else if (subsubmenu == "Back") {
            setSubSubMenuState([false, false]);
        }
        
        subSubMenuState[0] && setNavState([false,true,false]);
        arrStates ? setArrStates(false) : setArrStates(true);
    }

    return (
        <div className="control-width">
            {/* Navigation Menu
        ================================================== */}
            <Link className="pa-mobile-main-logo" to="/"><img alt="" src={revoLight} /></Link>
            <Link className={navState[0] ? "hc-nav-trigger hc-nav-1 toggle-open" : "hc-nav-trigger hc-nav-1"} onClick={() => handleNav()} ><span></span></Link>
            {<div>

                <nav className={navState[0] ? "hc-offcanvas-nav hc-nav-1 nav-levels-overlap nav-position-right disable-body nav-open" : "hc-offcanvas-nav hc-nav-1 nav-levels-overlap nav-position-right disable-body"} >
                    <div className={(navState[0] ? (navState[2] ? "nav-container nav-container2" : "nav-container") : "nav-container nav-container-effect")} style={navState[1] ? { visibility: "visible" } : { visibility: "hidden" }}>
                        <div className={navState[2] ? "nav-wrapper nav-wrapper-0 sub-level-open" : "nav-wrapper nav-wrapper-0"}> { /* nav-wrapper nav-wrapper-0 sub-level-open*/}
                            <div className="nav-content"  >
                                {/* Desktop Menu Logo */}
                                <Link className="navbar-brand" to="/"><img alt="" src={revoLight} /></Link>

                                {/* The Menu */}
                                <ul className="navbar-nav ml-auto">

                                    {/* Mobile Menu Logo (only use if "close" buttons are set to false in JS) */}
                                    <li data-nav-custom-content className="nav-item nav-item-custom custom-content pa-mobile-menu-logo">
                                        <div className="nav-custom-content">
                                            <Link className="pa-mobile-navbar-brand" to="/"><img alt="" src={revoLight} /></Link>
                                        </div>
                                    </li>

                                    {/* Regular Menu Items Start */}

                                    {
                                        navElements.map((e, i) => {
                                            return <li key={e + i} className={ e == "News" ? "nav-item  nav-parent" : navState[2] == e ? "nav-item menu-item-has-children nav-parent level-open" : "nav-item menu-item-has-children nav-parent"}>
                                                <div className="nav-item-wrapper">
                                                    <Link className="nav-link hvr-underline-from-center nav-item-link" to={e == "News" ? "/news" : ""} onClick={() => handleNav(e)}>{e}
                                                    {e == "News" ? <span></span> : <span className="nav-next"><span></span></span>}</Link>
                                                </div>
                                                <div className="nav-wrapper nav-wrapper-1" style={{ marginRight: "-20px" }}>
                                                    <div className="nav-content">
                                                        <h2 className="level-title">{subSubMenuState[0] ? `${e} - ${subSubMenuState[0]}` : e}</h2>
                                                        <ul className="pa-submenu-ul">
                                                            <li className="nav-item nav-back"><div className="nav-item-wrapper">{ subSubMenuState[0] ? <Link className="nav-back-button" onClick={() => handleSubSubMenu("Back")}>Back <span></span></Link> : <Link className="nav-back-button" onClick={() => handleNav("Back")}>Back <span></span></Link>}</div></li>
                                                            {
                                                                !subSubMenuState[0] && subMenu[i].map((s) => {
                                                                    return <li key={s.name} className={"nav-item"}>
                                                                            <div className="nav-item-wrapper">{ s.name == "API" || s.name == "Explorer" || s.name == "Technology" || s.name == "Faucets" ? <Link onClick={() => handleSubSubMenu(s.name)} to={``} className="nav-link nav-item-link">{s.name}<span className="nav-next"><span></span></span></Link> : s.url.includes("http") ? <a target="_blank" href={`${s.url}`} className="nav-link nav-item-link">{s.name}</a> : <Link to={`/${s.url}`} className="nav-link nav-item-link">{s.name}</Link>}
                                                                            </div>
                                                                    </li>
                                                                })
                                                            }{
                                                                subSubMenuState[0] && subSubMenu[subSubMenuState[1]]?.map((s) => {
                                                                    return <li key={s.name} className={"nav-item"}>
                                                                            <div className="nav-item-wrapper"> {s.url.includes("https") ? (<a target="_blank" href={s.url} style={s.name == "Mainnet" ? {color: `#00b712`} : s.name == "Testnet" ? {color: `#d22b2b`} : s.name == "Alphanet" ? {color: `#c9a204`} : {}} className="nav-link nav-item-link">{s.name}</a>) : (<Link to={`/${s.url}`} onClick={handleSubSubMenu} className="nav-link nav-item-link">{s.name}</Link>)} 
                                                                            </div>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>}
        </div>
    )
}